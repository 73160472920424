<template>
  <v-row dense>
    <v-col cols="12">
      <plot-widget />
    </v-col>
    <!-- <v-col cols="12" md="4">
      <v-card color="blue-grey darken-4 ma-4" style="height: 100%">
        <v-card-text></v-card-text>
      </v-card>
    </v-col> -->
  </v-row>
</template>

<script>
import PlotWidget from '../components/Widgets/Plots/Index.vue'

export default {
  components: {
    PlotWidget
  }
}
</script>
