<template>
  <v-card color="blue-grey darken-4 ma-4" style="height: 100%">
    <v-toolbar color="blue-grey darken-3">
      <v-toolbar-title>
        <v-autocomplete
          :items="devices"
          placeholder="Выберите устройство"
          item-text="title"
          item-value="id"
          dense
          outlined
          hide-details
          v-model="device"
          @change="plot"
        />
      </v-toolbar-title>
      <v-menu
        ref="menu"
        v-model="isOpen"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template #activator="{ on }">
          <v-text-field
            class="mx-4"
            :value="inputDate"
            prepend-icon="mdi-calendar"
            autocomplete="off"
            outlined
            dense
            hide-details
            clearable
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="range"
          range
          locale="ru"
          first-day-of-week="1"
          @input="onChangeRange"
        />
      </v-menu>
      <v-btn
        icon
        @click="excel"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          icon
          @click="settings = true"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text :style="'height: ' + plotHeight + 'px; overflow-y: auto;'">
      <plot
        :data="data"
        :schema="preset.schema"
        :getLatestData = "getLatestData"
      />
      <plot-settings
        v-model="settings"
        :device="getDevice()"
        :preset="preset"
        @close="settings = false"
        @save="afterSaveSettings"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Plot from '@/components/Common/Plots/TimeSeries'
import PlotSettings from '@/components/Settings/Plots/Device'

export default {
  components: {
    Plot,
    PlotSettings
  },

  data: () => ({
    devices: [],
    data: [],
    range: null,
    device: {},
    preset: {},
    settings: false,
    isOpen: false
  }),

  mounted () {
    this.getDevices().then((response) => {
      this.devices = response
      if (this.devices.length) {
        this.device = this.devices[0].id
        this.plot()
      }
    })
  },

  methods: {
    ...mapActions({
      getDevices: 'devices/index',
      getData: 'devices/data',
      xlsData: 'devices/xlsData',
      getPreset: 'plotPresets/index',
      setLastData: 'devices/setLastData'
    }),

    afterSaveSettings () {
      this.settings = false
      this.plot()
    },

    getDevice () {
      return this.devices.find(d => d.id === this.device)
    },

    onChangeRange () {
      if (this.range?.length > 1) {
        this.plot()
      }
    },

    plot () {
      Promise.all([
        this.getData({
          id: this.device,
          params: {
            params: { latest: false, range: this.range }
          }
        }),
        this.getPreset({
          params: {
            device_id: this.device,
            default: true
          }
        })
      ]).then(([data, preset]) => {
        this.preset = preset || {}
        this.data = data
      })
    },

    excel () {
      this.xlsData({
        id: this.device,
        params: {
          params: { latest: false, range: this.range }
        }
      })
    },

    getLatestData () {
      return this.device ? this.lastData[this.device] : null
    }
  },

  computed: {
    ...mapGetters({
      lastData: 'devices/lastData'
    }),

    plotHeight () {
      return window.innerHeight - 120
    },

    inputDate () {
      return this.range
    }
  }
}
</script>

<style>

</style>
